import React from "react";

import { useTranslation } from "react-i18next";

import girl from '../../img/CostsCharges/img.png'

import Marquee from "react-fast-marquee";
import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[50px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t('costscharges.title')}</span></h1>
                        <p className="hidden xl:block mt-[35px] max-w-[525px] inter text-[16px] text-[#212121] font-light">{t('costscharges.text')}</p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.site">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('costscharges.button')}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px] mx-[20px] xl:mx-0">
                        <Fade duration={1000}><img src={girl} alt="" className="xl:ml-[130px] mx-auto"/></Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <a href="https://user.topforex.site">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('costscharges.button')}</p>
                                </div>
                            </a>
                        </div>
                        <p className="md:text-center xl:text-left block xl:hidden mt-[90px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t('costscharges.text')}</p>
                    </div>
                </div>
           </div>
           <Marquee autoFill className="bg-[#DCF598] py-[15px] mt-[70px] xl:mt-[140px]">
                <p className="inter text-[20px] xl:text-[30px] font-semibold text-[#212121] mr-[20px]">Spot Your Opportunity, Trade with Lower Costs</p>
           </Marquee>
        </div>
    );
}

export default Main;