import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const productsData = [
    { 
        index: 0,
        title: 'products.indices.title',
        heights: ['87px', '56px', '87px', '68px', '68px', '82px', '112px'],
        price: '$5,501',
        price2: '$6,802',
        text: 'products.indices.text'
    },
    { 
        index: 1,
        title: 'products.forex.title',
        heights: ['87px', '56px', '87px', '104px', '68px', '82px', '112px'],
        price: '$15,501',
        price2: '$4,802',
        text: 'products.forex.text'
    },
    { 
        index: 2,
        title: 'products.shares.title',
        heights: ['64px', '71px', '96px', '82px', '89px', '56px', '82px'],
        price: '$10,201',
        price2: '$2,102',
        text: 'products.shares.text'
    },
    { 
        index: 3,
        title: 'products.commodities.title',
        heights: ['19px', '43px', '52px', '96px', '54px', '94px', '104px'],
        price: '$3,258',
        price2: '$2,809',
        text: 'products.commodities.text'
    },
    { 
        index: 4,
        title: 'products.cryptocurrencies.title',
        heights: ['89px', '77px', '77px', '103px', '43px', '109px', '125px'],
        price: '$40,125',
        price2: '$6,804',
        text: 'products.cryptocurrencies.title'
    }
];

function ProductsTrade () {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto" dir="ltr">
                <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold mx-[20px] xl:mx-0">{t('products.title')}</h1>
                <div className="mx-[20px] xl:mx-0 flex flex-col sm:flex-row justify-between items-center mt-[35px]">
                    <div className="max-w-[525px]">
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121]">{t('products.text1')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('products.text2')}</p>
                        <div className="mt-[35px] flex flex-col gap-[2px]">
                            {productsData.map((product) => (
                                <div 
                                    key={product.index} 
                                    className={activeIndex === product.index ? 'productsbutton w-[222px] cursor-pointer' : 'productsbuttondis w-[222px] cursor-pointer'} 
                                    onClick={() => handleButtonClick(product.index)}
                                >
                                    <p className="inter text-[30px] font-light text-[#212121]">0{product.index + 1}</p>
                                    <p className="text-[16px] font-medium text-[#212121] inter ml-[20px]">{t(product.title)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div class="relative xl:right-[90px] scale-[70%] xl:scale-100">
                        <svg width="525" height="448" viewBox="0 0 525 448" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_306_393" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M525 20C525 8.95431 516.046 0 505 0H231C219.954 0 211 8.9543 211 20V133C211 144.046 202.046 153 191 153H20C8.95431 153 0 161.954 0 173V428C0 439.046 8.9543 448 20 448H505C516.046 448 525 439.046 525 428V153V20Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M525 20C525 8.95431 516.046 0 505 0H231C219.954 0 211 8.9543 211 20V133C211 144.046 202.046 153 191 153H20C8.95431 153 0 161.954 0 173V428C0 439.046 8.9543 448 20 448H505C516.046 448 525 439.046 525 428V153V20Z" fill="#F3F2F2"/>
                            <path d="M231 1H505V-1H231V1ZM212 133V20H210V133H212ZM20 154H191V152H20V154ZM1 428V173H-1V428H1ZM505 447H20V449H505V447ZM524 153V428H526V153H524ZM524 20V153H526V20H524ZM505 449C516.598 449 526 439.598 526 428H524C524 438.493 515.493 447 505 447V449ZM-1 428C-1 439.598 8.40202 449 20 449V447C9.50659 447 1 438.493 1 428H-1ZM20 152C8.40202 152 -1 161.402 -1 173H1C1 162.507 9.50659 154 20 154V152ZM210 133C210 143.493 201.493 152 191 152V154C202.598 154 212 144.598 212 133H210ZM505 1C515.493 1 524 9.50659 524 20H526C526 8.40202 516.598 -1 505 -1V1ZM231 -1C219.402 -1 210 8.40202 210 20H212C212 9.50659 220.507 1 231 1V-1Z" fill="#212121" mask="url(#path-1-inside-1_306_393)"/>
                        </svg> 
                        <div class="absolute inset-0 flex flex-col items-start"> 
                            <div className="flex">
                                <div>
                                    <div className="ml-[30px] mt-[185px] w-[181px] h-[78px] rounded-[15px] bg-[#AAE3DF] flex justify-center items-center">
                                        <h1 className="text-[35px] inter font-light text-[#212121]">{productsData[activeIndex].price2}</h1>
                                    </div>
                                </div>
                                <div>
                                    <div className="ml-[36px] mt-[25px] w-[242px] h-[236px] rounded-[15px] bg-[#3D3C3B]">
                                        <p className="pt-[12px] ml-[20px] text-[16px] inter font-light text-white">{t('products.total')}</p>
                                        <h1 className="text-[35px] inter font-light text-white ml-[20px] mt-[-5px]">{productsData[activeIndex].price}</h1>
                                        <div className="flex mx-[20px] gap-[14px] mt-[9px]">
                                            {productsData[activeIndex].heights.map((height, index) => (
                                                <div key={index} className="w-[17px] h-[125px] rounded-[20px] bg-[#565655] flex items-end">
                                                    <div style={{ height: `${height}` }} className={`product-bar w-[17px] rounded-[20px] bg-[#DCF598]`}></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[52px] mx-[30px] mt-[30px]">
                                <p className="max-w-[390px] inter text-[16px] text-[#212121] font-light">{t(productsData[activeIndex].text)}</p>
                            </div>
                            <Link to="https://user.topforex.site"  className="cursor-pointer buttontwo mt-[30px] ml-[30px] xl:ml-auto mr-[30px]">
                                <div>
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('products.button')}</p>
                                </div>
                            </Link>
                           
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default ProductsTrade;
