import React, { useState, useEffect } from "react";

import plus from '../img/Home/plus.svg'
import minus from '../img/Home/minus.svg'

import button from '../img/Home/button.png'

import { useTranslation, Trans } from "react-i18next";

const faqData1 = [
    {
        question: 'added5',
        answer: 'added11'
    },
    {
        question: 'added6',
        answer: 'added10'
    },
    {
        question: 'added7',
        answer: 'added12'
    },
    {
        question: 'added8',
        answer: 'added13'
    },
    {
        question: 'added9',
        answer: 'added14'
    },
]
const faqData2 = [
    {
        question: 'added16',
        answer: 'added17'
    },
    {
        question: 'added18',
        answer: 'added19'
    },
    {
        question: 'added20',
        answer: 'added21'
    },
    {
        question: 'added22',
        answer: 'added23'
    },
    {
        question: 'added24',
        answer: 'added25'
    },
]
const productsData = [
    { 
        index: 0,
        title: 'openaccount.1',
        price: '$250',
        text1: 'openaccount.17',
        text2: 'openaccount.18',
        text3: 'openaccount.19',
        svg4: plus,
        svg5: plus,
        svg6: plus,
        svg7: plus,
        svg8: minus,
        svg9: plus,
        svg10: minus,
        svg11: minus,
    },
    { 
        index: 1,
        title: 'openaccount.2',
        price: '$5.000',
        svg1: minus,
        text2: 'openaccount.21',
        text3: 'openaccount.22',
        svg4: plus,
        svg5: plus,
        svg6: plus,
        svg7: plus,
        svg8: plus,
        svg9: plus,
        svg10: minus,
        svg11: minus,
    },
    { 
        index: 2,
        title: 'openaccount.3',
        price: '$25.000',
        svg1: minus,
        text2: 'openaccount.23',
        text3: 'openaccount.24',
        svg4: plus,
        svg5: plus,
        svg6: plus,
        svg7: plus,
        svg8: plus,
        svg9: plus,
        svg10: plus,
        svg11: minus,
    },
    { 
        index: 3,
        title: 'openaccount.4',
        price: '$75.000',
        svg1: minus,
        text2: 'openaccount.25',
        text3: 'openaccount.26',
        svg4: plus,
        svg5: plus,
        svg6: plus,
        svg7: plus,
        svg8: plus,
        svg9: plus,
        svg10: plus,
        svg11: minus,
    },
    { 
        index: 4,
        title: 'openaccount.5',
        price: '$250.000',
        svg1: minus,
        text2: 'openaccount.27',
        text3: 'openaccount.28',
        svg4: plus,
        svg5: plus,
        svg6: plus,
        svg7: plus,
        svg8: plus,
        svg9: plus,
        svg10: plus,
        svg11: plus,
    },
]


function Accounts () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const [activeIndex, setActiveIndex] = useState(0);

      const handleButtonClick = (index) => {
          setActiveIndex(index);
      };
      
      const { t } = useTranslation();

      const [openIndex1, setOpenIndex1] = useState(1);

      const toggleText1 = (index) => {
          setOpenIndex1((prevIndex) => (prevIndex === index ? null : index));
      };

      const [openIndex2, setOpenIndex2] = useState(1);

      const toggleText2 = (index) => {
          setOpenIndex2((prevIndex) => (prevIndex === index ? null : index));
      };
  
      
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[160px]">
            <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold text-center mx-[20px] xl:mx-0">{t("openaccount.title")}</h1>
            <p className="mt-[15px] max-w-[627px] text-center mx-auto inter font-[300] text-[#212121]">{t("openaccount.title2")}</p>
            <div className="flex flex-wrap  mb-[-1px] mt-[70px] justify-between gap-[10px]">
                {productsData.map((product) => (
                    <div 
                        key={product.index}
                        className={`transition-all duration-700 flex-grow-[1] w-[48%] xl:w-[212px] h-[80px] xl:rounded-t-[20px] border border-[#212121] ${activeIndex === product.index ? 'bg-[#F3F2F2]' : 'bg-[#DCF598]'} cursor-pointer flex flex-col justify-center items-center`}
                        onClick={() => handleButtonClick(product.index)}
                    > 
                        <p className={`transition-all duration-500 font-[500] ${activeIndex === product.index ? 'text-[#02995A]' : 'text-[#000]'} text-[16px] xl:text-[20px] inter`}>{t(product.title)}</p>
                        <p className={`transition-all duration-500 font-[300] ${activeIndex === product.index ? 'text-[#02995A]' : 'text-[#000]'} text-[14px] xl:text-[16px] inter`}>{t(product.price)}</p>
                    </div>
                ))}
            </div>
            <div className="pb-[20px] xl:pb-[50px] border border-[#212121] bg-[#F3F2F2] rounded-b-[20px] flex flex-col mt-[10px] xl:mt-0">
                <h1 className="mt-[20px] xl:mt-[40px] mx-auto text-[22px] xl:text-[35px] inter font-[800] text-[#02995A]">
                    {t(productsData[activeIndex].title)} {t("openaccount.20")}
                </h1>
                <div className="grid grid-cols-2 mx-[20px] mt-[30px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.6")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text1)}</p>
                            <img src={productsData[activeIndex].svg1} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.7")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text2)}</p>
                            <img src={productsData[activeIndex].svg2} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.8")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text3)}</p>
                            <img src={productsData[activeIndex].svg3} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.9")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text4)}</p>
                            <img src={productsData[activeIndex].svg4} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.10")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text5)}</p>
                            <img src={productsData[activeIndex].svg5} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.11")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text6)}</p>
                            <img src={productsData[activeIndex].svg6} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.12")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text7)}</p>
                            <img src={productsData[activeIndex].svg7} alt="" /> 
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.13")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text8)}</p>
                            <img src={productsData[activeIndex].svg8} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.14")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text9)}</p>
                            <img src={productsData[activeIndex].svg9} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.15")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text10)}</p>
                            <img src={productsData[activeIndex].svg10} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <div className="grid grid-cols-2 mx-[20px] xl:mx-auto">
                    <div className="xl:w-[400px] flex justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t("openaccount.16")}</p>
                        </div>  
                    </div>
                    <div className="flex xl:w-[400px] justify-end">
                        <div className="w-[250px]">
                            <p className="inter text-[14px] xl:text-[16px]">{t(productsData[activeIndex].text11)}</p>
                            <img src={productsData[activeIndex].svg11} alt="" />       
                        </div>
                    </div>
                </div>
                <div className="xl:w-[893px] h-[1px] bg-[#2121214D] mt-[10px] mb-[10px] mx-[20px] xl:mx-auto"></div>
                <a href="https://user.topforex.site" className="buttontwo mt-[50px] mx-auto">
                    <div >
                        <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('added4')}</p>
                    </div>
                </a>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px]">
                <div>
                    <h1 className="xl:max-w-[350px] text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t("added1")}</h1>
                    <p className="xl:max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-[300] mt-[30px]">{t("added2")}</p>
                    <p className="xl:max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-[300] mt-[15px]">{t("added3")}</p>
                    <a href="https://user.topforex.site">
                        <div className="buttontwo mt-[50px] mx-auto">
                            <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('added4')}</p>
                        </div>
                    </a>
                </div>
                <div className="xl:w-[525px]">
                    {faqData1.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between mt-[20px] transition-all duration-300 ${openIndex1 === index ? 'h-auto bg-[#DCF598]' : 'bg-[#F3F2F2] h-[54px]'}`}>
                                <div className='mx-[25px]'>
                                    <p className='mt-[15px] manrope text-[14px] xl:text-[16px] text-[#212121] font-[500] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex1 === index && (
                                    <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[393px]'><Trans>{t(faq.answer)}</Trans></p>
                                    )}
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText1(index)}>
                                    <img src={button} alt="" className={`${openIndex1 === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-[80px] xl:mt-[160px] pb-[80px] xl:pb-[160px]">
                <h1 className="xl:max-w-[350px] text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t("added15")}</h1>
                {faqData2.map((faq, index) => (
                    <div key={index}>
                        <div className={`flex justify-between mt-[20px] transition-all duration-300 ${openIndex2 === index ? 'h-auto bg-[#DCF598]' : 'bg-[#F3F2F2]'}`}>
                            <div className='mx-[25px]'>
                                <p className='mt-[15px] manrope text-[14px] xl:text-[16px] text-[#212121] font-[500] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                {openIndex2 === index && (
                                <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[1000px]'><Trans>{t(faq.answer)}</Trans></p>
                                )}
                            </div>
                            <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText2(index)}>
                                <img src={button} alt="" className={`${openIndex2 === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                            </div>
                        </div>
                    </div>
                ))} 
            </div>
        </div>
    );
}

export default Accounts;