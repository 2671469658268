import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import girl from '../img/Products/img.png';

import Marquee from "react-fast-marquee";
import Fade from "react-reveal/Fade";

function Cryptocurrency() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[80px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold">
                            <span className="text-[#02995A]">{t('cryptocurrency.page.title')}</span>
                        </h1>
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold">
                            {t('cryptocurrency.page.subtitle')}
                        </h1>
                        <p className="hidden xl:block mt-[35px] max-w-[525px] inter text-[16px] text-[#212121] font-light">
                            {t('cryptocurrency.page.text')}
                        </p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.site">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">
                                        {t('cryptocurrency.page.startNow')}
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px]">
                        <Fade duration={1000}>
                            <img src={girl} alt="" className="xl:ml-[130px] mx-auto xl:mt-[100px]" />
                        </Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <div className="buttontwo mt-[50px]">
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">
                                    {t('cryptocurrency.page.startNow')}
                                </p>
                            </div>
                        </div>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[50px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">
                            {t('cryptocurrency.page.text')}
                        </p>
                    </div>
                </div>
            </div>
            <Marquee autoFill className="bg-[#DCF598] py-[15px] mt-[60px] xl:mt-[90px]">
                <p className="inter text-[20px] xl:text-[30px] font-semibold text-[#212121] mr-[20px]">
                    {t('cryptocurrency.page.marquee')}
                </p>
            </Marquee>
        </div>
    );
}

export default Cryptocurrency;
