import React, { useState } from "react";

import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import icon from '../../img/Home/iicon.svg'
import icon1 from '../../img/Home/iicon1.svg'
import icon2 from '../../img/Home/iicon2.svg'

function AlphaFlowSignals () {

    const { t } = useTranslation(); // Получаем функцию t для перевода текста

    const [swiper, setSwiper] = useState({});

    return(
        <div>
           <div className="max-w-[1280px] mx-auto flex flex-col">
                <div className="flex flex-col xl:flex-row mx-[20px] xl:mx-0">
                    <div className="max-w-[314px] mr-[30px]">
                        <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t('alphaflowsignals.title')}</h1>
                        <h1 className="text-[#212121] inter text-[22px] xl:text-[35px] font-bold">{t('alphaflowsignals.text1')}</h1>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[30px] xl:mt-[35px]">{t('alphaflowsignals.text2')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[10px] xl:mt-[15px]">{t('alphaflowsignals.text3')}</p>
                    </div>
                    <div className="mt-[15px] xl:mt-0 max-w-[900px]">
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            slidesPerView={5}
                            loop={true}
                            onInit={(e) => {
                                setSwiper(e);
                            }}
                            breakpoints={{
                                300: {
                                slidesPerView: 1,
                                },
                                768: {
                                slidesPerView: 2.3,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <div className="md:w-[383px] md:h-[314px] block1 flex flex-col">
                                    <img src={icon} alt="" className="mt-[15px] mx-[15px] w-[80px] h-[80px]"/>
                                    <p className="mx-[15px] mt-[30px] xl:mt-[50px] max-w-[300px] font-light text-[14px] xl:text-[14px] text-[#212121]">{t('alphaflowsignals.swiper1')}</p>
                                    <a href="https://user.topforex.site" className="cursor-pointer buttontwo mt-[30px] ml-auto mx-[15px] mb-[15px] xl:mb-0">
                                        <div>
                                            <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('alphaflowsignals.button')}</p>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="md:w-[383px] md:h-[314px] block2 flex flex-col">
                                    <img src={icon1} alt="" className="mt-[15px] mx-[15px] w-[80px] h-[80px]"/>
                                    <p className="mx-[15px] mt-[30px] xl:mt-[50px] max-w-[250px] font-light text-[14px] xl:text-[14px] text-[#212121]">{t('alphaflowsignals.swiper2')}</p>
                                    <a href="https://user.topforex.site" className="cursor-pointer buttontwo mt-[30px] ml-auto mx-[15px] mb-[15px] xl:mb-0">
                                        <div>
                                            <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('alphaflowsignals.button')}</p>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="md:w-[383px] md:h-[314px] block1 flex flex-col">
                                    <img src={icon2} alt="" className="mt-[15px] mx-[15px] w-[80px] h-[80px]"/>
                                    <p className="mx-[15px] mt-[30px] xl:mt-[50px] max-w-[300px] font-light text-[14px] xl:text-[14px] text-[#212121]">{t('alphaflowsignals.swiper3')}</p>
                                    <a href="https://user.topforex.site" className="cursor-pointer buttontwo mt-[30px] ml-auto mx-[15px] mb-[15px] xl:mb-0">
                                        <div>
                                            <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('alphaflowsignals.button')}</p>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className='flex mt-[20px] gap-[10px] ml-auto mr-[20px] xl:mr-0'>
                    <div onClick={() => swiper.slidePrev()}>
                        <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="29.5" y="29.5" width="29" height="29" rx="14.5" transform="rotate(-180 29.5 29.5)" stroke="#493843"/>
                            <path d="M7.64645 14.6464C7.45118 14.8417 7.45118 15.1583 7.64645 15.3536L10.8284 18.5355C11.0237 18.7308 11.3403 18.7308 11.5355 18.5355C11.7308 18.3403 11.7308 18.0237 11.5355 17.8284L8.70711 15L11.5355 12.1716C11.7308 11.9763 11.7308 11.6597 11.5355 11.4645C11.3403 11.2692 11.0237 11.2692 10.8284 11.4645L7.64645 14.6464ZM22 14.5L8 14.5V15.5L22 15.5V14.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                    <div onClick={() => swiper.slideNext()}>
                        <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#493843"/>
                            <path d="M22.3536 15.3536C22.5488 15.1583 22.5488 14.8417 22.3536 14.6464L19.1716 11.4645C18.9763 11.2692 18.6597 11.2692 18.4645 11.4645C18.2692 11.6597 18.2692 11.9763 18.4645 12.1716L21.2929 15L18.4645 17.8284C18.2692 18.0237 18.2692 18.3403 18.4645 18.5355C18.6597 18.7308 18.9763 18.7308 19.1716 18.5355L22.3536 15.3536ZM8 15.5L22 15.5L22 14.5L8 14.5L8 15.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                </div>
           </div>
        </div>
    );
}

export default AlphaFlowSignals;